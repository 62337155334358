.top-matter {
  padding: 10px 20px 1px 20px;
}

.paragraph-like {
  margin: 1rem 0;
  vertical-align: middle;
}

.icon-space {
  width: 0.95rem;
  height: 0.95rem;
  margin-right: 7px;
  position: relative;
  top: -0.05rem;
}

.clicky {
  cursor: pointer;
}

.reduced-space {
  margin-bottom: 0;
}