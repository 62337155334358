.center {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: center;
}

.intro-to-demo-mode {
  background-color: rgb(255, 229, 219);
  width: 75vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.intro-to-demo-mode > * {
  text-align: center;
}

.add-space {
  margin-top: 20px;
}

.bottom-spacing {
  margin-bottom: 60px;
}



/* styling for demo-mode-login-info */

.single-cell-grid-container {
  width: 100%;
  display: grid;
  grid-template-columns: min(fit-content, 100%);
}

.grid-cell-flex-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.demo-mode-login-info.flex-container {
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-row {
  /* max-width: fit-content; */
  width: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.info-type {
  width: 5em;
  text-align: left;
}
.info-data {
  width: max-content;
  text-align: left;
  font-weight: bold;
  margin-left: 10px;
}