:root {
  --border-radius: 2px;
  --border-gray: rgba(0,0,0,.2);
  --border-gray-toggle:             rgb(191, 191, 191);
  --background-gray-1-light:        #f4f4f4;
  --background-gray-2-medium-light: rgb(233, 233, 233);
  --background-gray-3-medium-dark:  rgb(208, 208, 208);
  --background-gray-4-dark:         rgb(191, 191, 191);
}
/**
 * Color (#f4f4f4) chosen to attempt to match that of the TimeOptions react-
 * acceessible-accordion (See file at client/node_modules/react-accessible-
 * accordion/dist/fancy-example.css).
 */


.boxed {
  border: 1px solid var(--border-gray);
  border-radius: var(--border-radius);
  background-color: var(--background-gray-2-medium-light);
}
.boxed.upper-level > * {
  padding: 15px;
}

.query-section-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  background-color: var(--background-gray-2-medium-light);
}

.query-section {
  width: 80%;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 0;
}

.query-section > :not(:first-child) {
  margin: 15px 0 0 0;
}


.query-sub-title {
  background-color: var(--background-gray-1-light);
}

.query-option-space {
  background-color: var(--background-gray-2-medium-light);
}